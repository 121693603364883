
/**
 * Simple button.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Component, Vue, Prop } from '@/lib/decorator';
import { h } from 'vue';

type BtnSize = 'small' | 'normal' | 'medium' | 'large';
type BtnColor = 'primary' | 'secondary' | 'default';
type BtnVariant = 'contained' | 'text' | 'outlined' | 'icon';

interface Styles {
  root: string;
  fullwidth: string;
  [dynamicClass: string]: string;
}

@Component({})
export default class Btn extends Vue {
  @Prop({ required: false }) readonly to?: string;
  @Prop({ required: false }) readonly href?: string;
  @Prop({ default: false }) readonly fullwidth!: boolean;
  @Prop({ default: 'normal' }) readonly size!: BtnSize;
  @Prop({ default: 'contained' }) readonly variant!: BtnVariant;
  @Prop({ default: 'default' }) readonly color!: BtnColor;

  $style!: Styles;

  render() {
    const elClass = {
      [this.$style.root]: true,
      [this.$style[`${this.size}Size`]]: true,
      [this.$style[`${this.variant}Variant`]]: true,
      [this.$style[`${this.color}Color`]]: true,
      [this.$style.fullwidth]: this.fullwidth,
    };

    let attrs: { href?: string; [index: string]: string | number | undefined } = { tabindex: 0 };
    let props: { to?: string; [index: string]: string | number | undefined } = { tabindex: 0 };
    let tag = 'button';

    if (this.to) {
      tag = 'router-link';
      props = { to: this.to, ...this.$attrs };
    }
    if (this.href) {
      tag = 'a';
      attrs = { href: this.href, ...this.$attrs };
    }

    if (tag === 'button') {
      attrs = { type: 'button', ...attrs, ...this.$attrs };
    }

    return h(
      tag,
      {
        class: elClass,
        // on: this.$listeners,
        attrs,
        props,
      },
      this.$slots?.default ? this.$slots.default({}) : this.$slots.default
    );
  }
}
